import React from "react";
import { LocationProvider } from "@reach/router";
import AccountProvider from "./account";
import CartProvider from "./cart";
import ProductFinderProvider from "./product-finder";

const GlobalProvider = ({ children }) => {
    const [isHydrated, setIsHydrated] = React.useState(false);

    React.useEffect(() => {
        setIsHydrated(true);
    }, []);

    return (
        <div className={isHydrated ? "hydrated" : "not-hydrated"}>
            <AccountProvider>
                <CartProvider>
                    <ProductFinderProvider>
                        <LocationProvider>
                            {children}
                        </LocationProvider>
                    </ProductFinderProvider>
                </CartProvider>
            </AccountProvider>
        </div>
    );
};

export default GlobalProvider;
