export const checkWarrantyProducts = (items) => {
    const warrantyProductIds = [
        "7627392254006",
        "6950299500598",
        "6960883138614",
        "6960883138614",
        "6961395204150",
        "7196884434998",
        "7375024586806",
        "4454446039094",
        "6964648378422",
        "6964648411190",
        "6964648476726",
    ];

    if (!Array.isArray(items)) {
        throw new Error("Items must be an array.");
    }

    return items.some((item) => warrantyProductIds.some((_id) => item.node?.variant?.product?.id?.includes(_id)));
};

export default checkWarrantyProducts;
