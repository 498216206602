const variantUSP = [
    {
        key: "Waterproof",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/Waterproof-Icon.png",
        text: {
            supreme: "Yes",
            elite: "Yes",
            proguard: "Yes",
        },
    },
    {
        key: "UV Resistant",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/UV-Resistant-Icon.svg",
        text: {
            supreme: "High",
            elite: "Medium",
            proguard: "High",
        },
    },
    {
        key: "Warranty",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/Warranty-Icon.png",
        text: {
            supreme: "10 Years",
            elite: "5 Years",
            proguard: "3 Years",
        },
    },
    {
        key: "Weight",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/Weight-Icon.png",
        text: {
            supreme: "18 Oz",
            elite: "8 Oz",
            proguard: "12 Oz",
        },
    },
];

const variantText = {
    supreme: [
        { key: "material", value: "SEAL-TEC Technology, 1000 Denier One Side PVC Coated Fabric Diamond Finish" },
        { key: "feature", value: "High Durability, Heavy Duty, Tear & Abrasion Resistant" },
        { key: "ideal for", value: "Extreme Weather" },
    ],
    elite: [
        { key: "material", value: "600 Denier Solution Dyed Polyester Fabric" },
        { key: "feature", value: "Great Durability, Tear & Abrasion Resistant" },
        { key: "ideal for", value: "Moderate Weather" },
    ],
    proguard: [
        { key: "material", value: "1000 Denier Both Side PVC Coated Fabric" },
        { key: "feature", value: "Great Durability, Tear & Abrasion Resistant" },
        { key: "ideal for", value: "Moderate Weather" },
    ],
};

const tieDowns = [
    {
        key: 716,
        value: "Drawstring",
        img: "https://cdn.coversandall.com/option/value/picture/Drawstring.jpg",
        price: null,
    },
    {
        key: 717,
        value: "Elastic at Bottom",
        img: "https://cdn.coversandall.com/option/value/picture/elastic_at_bottom.jpg",
        price: 1.99,
    },
    {
        key: 718,
        value: "Push Clip to Secure Legs",
        img: "https://cdn.coversandall.com/option/value/picture/push_clip.jpg",
        price: 4.99,
    },
    {
        key: 719,
        value: "Elastic at Bottom + Push Clip to Secure Legs",
        img: "https://cdn.coversandall.com/option/value/picture/elastic_push_clip.jpg",
        price: 7.99,
    },
    {
        key: 720,
        value: "Split Zipper",
        img: "https://cdn.coversandall.com/option/value/picture/zipper.jpg",
        price: 9.99,
    },
    {
        key: 721,
        value: "No Tie down",
        img: "https://cdn.coversandall.com/option/value/picture/No_tie_down.jpg",
        price: null,
    },
];

const grommets = [
    {
        key: 722,
        value: "No Grommets",
        img: "https://cdn.coversandall.com/option/value/picture/No_Grommet.jpg",
        price: null,
    },
    {
        key: 723,
        value: "Grommets at every 6 Inch",
        img: "https://cdn.coversandall.com/option/value/picture/grommet.jpg",
        price: 7.99,

    },
    {
        key: 724,
        value: "Grommets at every 12 Inch",
        img: "https://cdn.coversandall.com/option/value/picture/grommet_1.jpg",
        price: 5.99,
    },
    {
        key: 725,
        value: "Grommets at every 24 Inch",
        img: "https://cdn.coversandall.com/option/value/picture/grommet_2.jpg",
        price: 3.99,
    },
];

const personalizationOptions = {
    text: "Print Text on Cover | Tarp",
    logo: "Print LOGO on Cover | Tarp",
};

const cartPatioProperties = [
    {
        key: "personalization",
        value: "Personalize Type",
    },
    {
        key: "logo",
        value: "Logo",
    },
    {
        key: "personalizationSide",
        value: "Print Sides",
    },
    {
        key: "personalizationColor",
        value: "Color",
    },
    {
        key: "personalizationFont",
        value: "Font",
    },
];

const patioData = {
    variantUSP, variantText, tieDowns, grommets, personalizationOptions, cartPatioProperties,
};

export default patioData;
