/* eslint-disable no-undef */
import guid from "helpers/guid";
import { CURRENCY } from "utils/constants";

const consentGranted = async () => new Promise((resolve) => {
    const checkCookieConsentApi = () => {
        if (window.CookieConsentApi && window.CookieConsentApi.getUserPreferences) {
            resolve(window.CookieConsentApi.getUserPreferences().accept_type === "all");
        } else {
            setTimeout(checkCookieConsentApi, 200);
        }
    };

    checkCookieConsentApi();
});

const eventId = () => `event_${guid()}`;

const getTotalPrice = (items) => Number(items.reduce((acc, item) => acc + item.quantity * item.price, 0).toFixed(2));

export const customerInfo = (customer) => {
    const { id: userId, firstName, lastName, email } = customer;
    return {
        userId, firstName, lastName, email,
    };
};

const trackEvent = (event, data) => {
    if (consentGranted()) {
        dataLayer.push({
            event,
            event_id: eventId(),
            ...data,
        });
    }
};

const trackEcomEvent = (event, ecommerce) => {
    if (consentGranted()) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        trackEvent(event, {
            ecommerce: {
                ...ecommerce,
                currency: CURRENCY,
            },
        });
    }
};

const mapItems = (products) => products.map((product, index) => {

    // eslint-disable-next-line no-param-reassign
    product = product?.node ?? product;

    const variant = product?.currentVariant?.node ?? product?.variant;
    const fabricOption = variant?.selectedOptions?.find((option) => option.name === "Fabric");
    const colorOption = variant?.selectedOptions?.find((option) => option.name === "Color");

    const item = {
        item_id: product?.variant?.id,
        item_name: product?.title,
        affiliation: "Seal Skin Covers",
        currency: CURRENCY,
        index: product?.index ?? index,
        item_brand: "Seal Skin Covers",
        item_category: product?.item_metrics?.item_category,
        item_category2: product?.item_metrics?.item_category2,
        item_category3: product?.item_metrics?.item_category3,
        item_category4: product?.item_metrics?.item_category4,
        item_category5: fabricOption?.value ?? product?.item_metrics?.item_category5,
        item_category6: colorOption?.value ?? product?.item_metrics?.item_category6,
        item_variant: variant?.title,
        price: variant?.price?.amount || variant?.price,
        quantity: product.quantity ?? 1,
    };
    if (product?.discounts?.length) {
        let itemDiscount = 0;
        const coupons = [];

        product.discounts.forEach((discount) => {
            itemDiscount += discount.amount;
            coupons.push(discount.title);
        });
        item.discount = itemDiscount;
        item.coupon = coupons.join(",");
    }
    return item;
});

export const trackAddToCart = (products) => {
    const items = mapItems([].concat(products));
    trackEcomEvent("add_to_cart", {
        items,
        value: getTotalPrice(items),
    });
};

export const trackRemoveFromCart = (products) => {
    const items = mapItems([].concat(products));
    trackEcomEvent("remove_from_cart", {
        items,
        value: getTotalPrice(items),
    });
};

export const trackQuantityChange = (products, quantity) => {
    if (quantity === 0) trackRemoveFromCart(products);
    if (quantity !== 0) trackAddToCart(products);
};

export const trackViewItem = (product) => {
    trackEcomEvent("view_item", { item: mapItems([product]) });
};

export const trackSliderClick = (product) => {
    trackEcomEvent("slider_click", { item: mapItems([product]) });
    console.log('slider_click', product);
};

export const trackBeginCheckout = (cart) => {
    const ecommerce = {
        value: cart.totalPrice,
        items: mapItems(cart.items),
    };

    if (cart.discountCode) ecommerce.coupon = cart.discountCode;

    trackEcomEvent("begin_checkout", ecommerce);
};

export const trackLogIn = (customer) => trackEvent("login", { customer: customerInfo(customer) });

export const trackSignUp = (customer) => trackEvent("sign_up", { customer: customerInfo(customer) });

export const trackViewCart = (cart) => {
    const items = mapItems(cart.items);
    trackEcomEvent("view_cart", {
        items,
        value: getTotalPrice(items),
        coupon: cart.discountCode,
    });
};

export const trackViewCollection = (products, item_list_name, item_list_id) => {
    const items = mapItems(products);
    trackEcomEvent("view_item_list", { items, item_list_name, item_list_id });
};

export const trackSelectItem = (product, item_list_name, item_list_id) => {
    const items = mapItems([product]);
    trackEcomEvent("select_item", { items, item_list_name, item_list_id });
};
