exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-account-forgot-password-js": () => import("./../../../src/templates/account/forgot-password.js" /* webpackChunkName: "component---src-templates-account-forgot-password-js" */),
  "component---src-templates-account-login-js": () => import("./../../../src/templates/account/login.js" /* webpackChunkName: "component---src-templates-account-login-js" */),
  "component---src-templates-account-order-index-js": () => import("./../../../src/templates/account/order/index.js" /* webpackChunkName: "component---src-templates-account-order-index-js" */),
  "component---src-templates-account-profile-addresses-js": () => import("./../../../src/templates/account/profile/addresses.js" /* webpackChunkName: "component---src-templates-account-profile-addresses-js" */),
  "component---src-templates-account-profile-index-js": () => import("./../../../src/templates/account/profile/index.js" /* webpackChunkName: "component---src-templates-account-profile-index-js" */),
  "component---src-templates-account-register-js": () => import("./../../../src/templates/account/register.js" /* webpackChunkName: "component---src-templates-account-register-js" */),
  "component---src-templates-account-reset-password-js": () => import("./../../../src/templates/account/reset-password.js" /* webpackChunkName: "component---src-templates-account-reset-password-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog/category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-category-landing-js": () => import("./../../../src/templates/category/landing.js" /* webpackChunkName: "component---src-templates-category-landing-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-design-system-index-js": () => import("./../../../src/templates/design-system/index.js" /* webpackChunkName: "component---src-templates-design-system-index-js" */),
  "component---src-templates-discount-and-redirect-js": () => import("./../../../src/templates/discount-and-redirect.js" /* webpackChunkName: "component---src-templates-discount-and-redirect-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-preview-index-js": () => import("./../../../src/templates/preview/index.js" /* webpackChunkName: "component---src-templates-preview-index-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

